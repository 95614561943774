import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/handbook",
    name: "Handbook",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/handbook.vue"),
  },
  {
    path: "/situation",
    name: "situation",
    component: () => import("../views/situation.vue"),
  },
  {
    path: "/active",
    name: "active",
    component: () => import("../views/active.vue"),
  },

  {
    path: "/newsList",
    name: "newsList",
    component: () => import("../views/newsList.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news.vue"),
  },
  {
    path: "/exhibitor",
    name: "exhibitor",
    component: () => import("../views/exhibitor.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
});

export default router;
