<template>
  <div class="home">
    <div class="count">访问量：{{ count }}</div>
    <div class="top">
      <div class="address">2024 · 中国（太原）</div>
      <div class="title">国际能源产业博览会</div>
      <div class="subtitle">CHINA (TAIYUAN) INTERNATIONAL ENERGY INDUSTRY EXPO 2024</div>
      <div class="line"></div>
      <div class="tag">发展能源新质生产力 共建清洁美丽世界</div>
      <div class="line"></div>
      <div class="location"><i class="el-icon-location-outline"></i>山西潇河国际会展中心</div>
      <div class="city">中国·太原</div>
      <div class="glide">
        <img src="../assets/gxImg/home/glide.png" alt="" />
      </div>

      <section class="tag-list">
        <div v-for="(item, index) in tagList" :key="index" @click="toDetail(item, index)">
          {{ item.title }}
        </div>
      </section>
    </div>
    <div class="center">
      <div class="title">往届能博会</div>
      <div class="subtitle">Previous Energy Expos</div>
      <div class="tabs">
        <li
          v-for="(item, index) in ['线上展厅', '线下全景']"
          :key="index"
          :class="{ active: index === active }"
          @click="handleClickTab(index)"
        >
          {{ item }}
        </li>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { homeColumn, getView } from '../api/index'
import Footer from '../components/Footer.vue'
export default {
  components: {
    Footer,
  },
  name: 'Home',
  data() {
    return {
      active: 0,
      tagList: [],
      count: 0,
    }
  },
  methods: {
    // 往届
    toPrevious() {
      window.open('http://www.cntyenergyexpo.com')
    },

    getList() {
      homeColumn().then(res => {
        this.tagList = res.rows
      })
    },

    handleClickTab(index) {
      const url = `https://720yun.com/vr/${index === 0 ? 'e632dxz8ycf' : '33522xbfula'}`
      window.open(url)

      if (index === 0) {
        getView(2).then(res => {
          this.count = res.data.count
        })
      }
      this.active = index
    },

    // 跳转
    toDetail(v, i) {
      console.log('v,i', i)
      if (i == 0) {
        this.$router.push(`/situation?section=${v.id}`)
      }
      if (i == 1) {
        this.$router.push(`/exhibitor?section=${v.id}`)
      }
      if (i == 2) {
        this.$router.push(`/newsList?section=${v.id}`)
      }
      if (i == 3) {
        this.$router.push(`/active?section=${v.id}`)
      }
      if (i == 4) {
        this.$router.push(`/Handbook?section=${v.id}`)
      }
    },
  },
  mounted() {
    getView(1).then(res => {
      this.count = res.data.count
    })
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.home {
  position: relative;
  .count {
    position: absolute;
    right: 4.5vw;
    top: 8vh;
    color: #ffffff;
    text-align: center;
    font-size: 0.8vw;
    padding: 1vh 1vw;
    background: url('../assets/gxImg/home/border.png') no-repeat center;
    background-size: 100% 100%;
    z-index: 10;
  }
  .top {
    position: relative;
    width: 100%;
    height: 100vh;
    background: url('../assets/gxImg/home/bg.jpg') center no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: #ffffff;
    padding-top: 22.6vh;
    box-sizing: border-box;
    .address {
      font-size: 4.8vh;
      font-weight: bold;
      font-family: Source Han Sans CN;
    }
    .title {
      padding-top: 4vh;
      font-size: 6.5vh;
      font-weight: bold;
      font-family: Source Han Sans CN;
      // letter-spacing: 10px;
    }
    .subtitle {
      padding-top: 2.3vh;
      padding-bottom: 1.85vh;
      font-size: 2.2vh;
      font-family: Myriad Pro;
      text-shadow: 0px 3px 4px rgba(12, 10, 162, 0.67);
    }
    .line {
      height: 1.2px;
      width: 663px;
      background-color: #ffffff;
      margin: 0 auto;
    }
    .tag {
      padding: 2.2vh 0;
      font-size: 3.3vh;
      font-weight: bold;
      font-family: Source Han Sans CN;
    }
    .location {
      font-size: 1.85vh;
      font-family: Adobe Heiti Std;
      padding: 3.14vh 0 3.88vh;
    }

    .city {
      font-size: 1.85vh;
    }

    .glide {
      position: absolute;
      bottom: 168px;
      left: 50%;
      transform: translateX(-50%);
      width: 55px;
      height: 3.15vh;

      > img {
        width: 100%;
        height: 100%;
        animation: glideAni 1s linear infinite;
      }
    }

    .tag-list {
      position: absolute;
      left: 0;
      bottom: 46px;
      width: 100%;
      display: flex;
      justify-content: center;
      column-gap: 16vh;
      font-size: 2.8vh;
      font-family: Source Han Sans CN;
      > div {
        cursor: pointer;
      }
    }
  }
  .center {
    text-align: center;
    color: #000000;
    width: 100%;
    height: 600px;
    margin: 18px 0 11px;
    background: url('../assets/gxImg/home/bg2.png') center no-repeat;
    background-size: 100% 100%;
    .title {
      margin-top: 182px;
      font-size: 72px;
      font-weight: bold;
    }
    .subtitle {
      font-size: 36px;
      margin-top: 30px;
    }

    .tabs {
      display: flex;
      padding: 3px;
      width: 360px;
      height: 72px;
      box-sizing: border-box;
      background: #b1c8f3;
      box-shadow: 0px 0px 30px 0px rgba(128, 128, 128, 0.3);
      border-radius: 100px;
      margin: 70px auto 0;
      user-select: none;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        font-weight: 500;
        font-size: 30px;
        color: #1b51b9;
        cursor: pointer;
        border-radius: inherit;

        &.active {
          color: #ffffff;
          background: linear-gradient(90deg, #0d1b8d 0%, #31a8ff 100%);
        }
      }
    }
  }
}

@keyframes glideAni {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
