import http from "../utils/request";
// console.log(http);
// 首页 栏目
export function homeColumn() {
  return http({
    method: "get",
    url: "/web/sectionList",
  });
}
//展会概况/服务指南
export function contentDetail(id) {
  return http({
    method: "get",
    url: "/web/contentDetail?section=" + id,
  });
}

// 新闻列表
export function contentList({ pageNum, pageSize, section }) {
  return http({
    method: "get",
    url: "/web/contentList",
    params: {
      pageNum,
      pageSize,
      section,
    },
  });
}

// 新闻详情
export function contentById(id) {
  return http({
    method: "get",
    url: "/web/contentById?id=" + id,
  });
}

//   展区接口
export function arealist() {
  return http({
    method: "get",
    url: "/web/arealist",
  });
}

// 展商接口
export function traderlist({ pageNum, pageSize, areaId }) {
  return http({
    method: "get",
    url: "/web/traderlist",
    params: {
      pageNum,
      pageSize,
      areaId,
    },
  });
}

//访问量
export function getView(val) {
  return http({
    method: "get",
    url: "/web/getView?type=" + val,
  });
}

//  页面富文本内容
// export function contentList(id) {
//   return http({
//     method: "get",
//     url: "/web/contentList?section=" + id,
//   });
// }

export function sectionList() {
  return http({
    method: "get",
    url: "/web/sectionList",
  });
}
export function timeList() {
  return http({
    method: "get",
    url: "/web/timeList",
  });
}
