!
<template>
  <div class="bottom">
    <div class="info"><span>主办单位：</span>山西省人民政府</div>
    <img src="../assets/gxImg/home/logo.png" class="logo" />
    <ul>
      <li><span>版权所有：</span>山西省投资促进局</li>
      <li><span>山西省投资促进局地址：</span>山西省太原市小店区龙盛街15号转型综改示范区科技创新孵化基地4层</li>
      <li>
        <p><span>电话：</span>0351-4675110</p>
        <p><span>邮箱：</span>sxtcjhzb@163.com</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style scoped lang="less">
.bottom {
  text-align: center;
  padding: 45px 0;
  background: #b1c7f3 url('~@/assets/gxImg/home/footerBg.png') no-repeat center bottom / 100% auto;

  .info {
    font-size: 22px;
    font-weight: bold;
  }

  .logo {
    width: 100px;
    height: auto;
    margin: 30px 0;
  }

  ul {
    li {
      font-size: 22px;
      margin-bottom: 16px;

      span {
        font-weight: bold;
      }

      &:last-child {
        display: flex;
        justify-content: center;
        column-gap: 40px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
